<template>
    <form @submit.prevent="submit()">
        <b-form-group :label="$t('crud.name')">
            <b-form-input v-model="record.name" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.zones.price_euro_pallet')">
            <b-form-input v-model="record.price_euro_pallet" @keypress.native="onlyNumber" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.zones.price_block_pallet')">
            <b-form-input v-model="record.price_block_pallet" @keypress.native="onlyNumber" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.zones.price_morning_delivery')">
            <b-form-input v-model="record.price_morning_delivery" @keypress.native="onlyNumber" required></b-form-input>
        </b-form-group>
        <button class="btn btn-primary" type="submit">{{ $t('crud.save') }}</button>
    </form>
</template>

<script>
    export default {
        name: 'zones-form-component',
        props: ['record'],
        methods: {
            submit() {
                this.$emit('submit');
            }
        }
    }
</script>
